import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = (props) => {
  const about = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-testimonials">
      <SEO title="Testimonials" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Congruent</h1>'/kənˈɡro͞oənt,ˈkäNGɡro͞oənt/': in agreement or harmony.
            </div>
            <div className="col-12">
              <h1>Alter Intelligence</h1>'/ˈôltər/ /inˈteləjəns/': we very much prefer machine intelligence, the use of "AI" here is a placebo effect.
            </div>
          </div>

          <br/>
          <p>
          Due to the repeated patterns we see in our research, mission and results, a combination of Congruence and Artificial intelligence better define us. Hence the name Congruent AI, to be congruent in research together for education, reproducibility and independence.
          </p>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {about.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-6 mb-1">
              <div className="testimonial">
                <div className="testimonials-meta">
                  <h2 className="testimonials-title">{edge.node.frontmatter.title}</h2>
                </div>
                <div
                  className="testimonials-content"
                  dangerouslySetInnerHTML={{ __html: edge.node.html }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            name
            jobtitle
          }
        }
      }
    }
  }
`;

export default About;
